.info {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  // overflow-y: auto;
  min-width: auto;
  width: 100%;
  max-width: 435px;
  flex-shrink: 1;
  max-height: calc(100vh - 134px);
  // overflow-y: scroll;
  height: 100%;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }

  &_blocked {
    overflow-y: hidden;
  }

  @media screen and (max-width: 1000px) {
    margin: 0 auto;
    max-width: none;
  }

  @media screen and (min-width: 420px) {
    min-width: 400px;
    max-height: calc(100vh - 134px);
  }

  @media screen and (min-width: 1000px) {
    width: auto;
    max-height: calc(100vh - 134px);
    // на мобилке отрезается часть контента без верхнего паддинга - закомментировал - переставил в десктопную версию, поскольку иначе прокручивалась вся страница
    &::-webkit-scrollbar {
      // height: 0;
      // width: 0;
      // padding: 0;
      width: 12px;
      background-color: #f2f5ff;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 30%);
      // border-radius: 10px;
      background-color: #f2f5ff;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 30%);
      background-color: #8391a5;
      min-height: 50px;
    }
  }
}

.heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 9px 0;
  margin: 0 23px 0;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.text {
  color: #363942;
  font-size: 13px;
  font-family: Roboto;
  line-height: 19px;
}

.filters {
  width: 29px;
  height: 29px;
  background: url(../../../assets/filters_square.svg) center no-repeat;
  transition: all 0.3s ease;

  &_active {
    background: url(../../../assets/filters_square_active.svg) center no-repeat;
  }
}

.sorting {
  width: 35px;
  height: 23px;
  background: url(../../../assets/logo_sort.svg) center no-repeat;
}

.tagContainer {
  width: 100%;
  position: relative;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   // left: 0px;
  //   right: 0;
  //   top: 0;
  //   bottom: 0;
  //   // height: 100%;
  //   width: 10px;
  //   background: linear-gradient(90deg, rgba(139, 167, 32, 0%) 0%, rgba(255, 255, 255, 100%) 100%);
  //   pointer-events: none;
  // }

  // &::after {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   // right: 0px;
  //   top: 0;
  //   bottom: 0;
  //   // height: 100%;
  //   width: 10px;
  //   background: linear-gradient(270deg, rgba(139, 167, 32, 0%) 0%, rgba(255, 255, 255, 100%) 100%);
  //   pointer-events: none;
  // }
}

.tags {
  padding: 0 23px 10px;
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  width: 100%;

  @media screen and (max-width: 420px) {
    padding: 0 8px 10px;
  }
}

.tag {
  min-width: 100px;
  // width: 100vw;
  border-radius: 75px;
  background: #e5eafc;
  // box-shadow: 17px 26px 25px 0 rgba(226, 226, 226, 25%);
  padding: 11px 7px;
  transition: all 0.3s ease;
  color: #363942;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 18px;
  text-wrap: nowrap;
  -webkit-tap-highlight-color: transparent;

  &_active {
    background: #e63946;
    color: #fff;
  }
}

.noApps {
  margin: 20px auto;
}

// .cards {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   padding: 0 23px 100px;
//   gap: 15px;
//   padding-top: 15px;
//   overflow-y: scroll;
// }

.cards {
  display: flex;
  flex-direction: column;
  padding: 0 23px 100px;
  gap: 15px;
  padding-top: 15px;
  overflow-y: auto;
  max-height: calc(100vh - 279px);
  height: 100%;

  &::-webkit-scrollbar {
    // height: 0;
    // width: 0;
    // padding: 0;
    width: 12px;
    background-color: #f2f5ff;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 30%);
    // border-radius: 10px;
    background-color: #f2f5ff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 30%);
    background-color: #8391a5;
    min-height: 50px;
  }

  @media screen and (max-width: 1000px) {
    align-items: center;
  }

  @media screen and (max-width: 440px) {
    padding: 0 10px 10px;
  }
}

.appContainer {
  width: 40px;
  height: 100%;
  position: relative;

  @media screen and (min-width: 1400px) {
    width: auto;
  }
}

.extendButton {
  position: absolute;
  z-index: 100;
  left: 20px;
  top: 12px;
  border-left: 1px solid #d9d9d9;
  border-right: transparent;

  &_active {
    left: 415px;
    border-left: transparent;
    border-right: 1px solid #d9d9d9;
  }

  @media screen and (min-width: 1400px) {
    display: none;
  }
}

.applications {
  position: absolute;
  z-index: 5;
  overscroll-behavior: contain;
  overflow-y: auto;
  max-height: calc(100vh - 134px);
  height: 100%;
  transition: all 0.3s ease;
  width: 0;
  background-color: #fff;
  border-right: 1px solid #d9d9d9;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }

  &_open {
    width: 435px;
  }

  @media screen and (min-width: 1400px) {
    position: relative;
    width: auto;
  }
}

.preloader {
  width: 100%;
  max-width: 100px;
  margin: 0 0 0 auto;
  position: sticky;
  right: 0;
  bottom: 35px;
  z-index: 1;
}