.main {
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 100vh;
  position: relative;
  padding: 64px 0 0;

  @media screen and (min-width: 800px) {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1000px) {
    padding: 0;
    display: grid;
    grid-template-columns: 3fr 2fr;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;

  @media screen and (min-width: 1000px) {
    padding: 65px 70px 0 50px;
    justify-content: flex-start;
  }

  @media screen and (min-width: 1200px) {
    padding: 65px 110px 0 70px;
  }
}

.form {
  padding: 30px 16px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  // gap: 80px;
}

.label {
  display: flex;
  flex-direction: column;
  position: relative;
  border: none;
  align-items: flex-start;
  width: 100%;
  max-width: 400px;
  margin-top: 10px;
}

.input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #d9d9d9;
  min-height: 37px;
  height: auto;
  resize: none;
  color: #000;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  box-sizing: border-box;
  outline: none;
  transition: all 0.3s ease;
  background: transparent;
  padding: 15px 0 5px;

  &:focus {
    outline: none;
    border-bottom: 1px solid #d9d9d9;
  }
  &:invalid {
    outline: none;
    border-bottom: 1px solid #fc0800;
  }

  &::placeholder {
    opacity: 0;
  }
  &:not(:placeholder-shown) + p,
  &:focus + p {
    top: 0;
    font-size: 14px;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition:
      background-color 0s 600000s,
      color 0s 600000s;

    & + p {
      top: 0;
      font-size: 14px;
    }
  }

  &_error {
    border-bottom: 1px solid #fc0800;
  }
}

.title {
  position: absolute;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin: 0;
  top: 20px;
  left: 0;
  font-family: Roboto;
  background-color: inherit;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  padding: 0;
  color: #8b8b8b;
}

.show {
  width: 30px;
  height: 28px;
  background: url(../../../assets/auth/eye_closed.svg) center no-repeat;
  background-size: 35px;
  position: absolute;
  right: 16px;
  bottom: 13px;
  transition: all 0.3s ease;

  &_active {
    width: 30px;
    height: 28px;
    background: url(../../../assets/auth/eye.svg) center no-repeat;
    background-size: 30px;
    right: 16px;
    bottom: 10px;
  }
}

.span_error {
  position: absolute;
  bottom: -20px;
  color: #fc0800;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.364px;
}

.submit_container {
  margin: 0 auto;
  position: relative;
}

.submit_error {
  position: absolute;
  left: -100px;
  right: -100px;
  text-align: center;
  color: #fc0800;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.364px;
}

.submit {
  margin-top: 40px;
}

.image {
  width: 390px;
  height: 300px;
}

.deskBack {
  display: none;
  grid-column: 1 / 1;
  background: var(--auth-gradient0);

  @media screen and (min-width: 800px) {
    display: block;
  }
}

.container {
  margin: 77px 0 0 73px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  max-width: 320px;
}

.logo {
  display: none;
  background: url(../../../assets/auth/company_logo.png) center no-repeat;

  @media screen and (min-width: 800px) {
    display: block;
    width: 200px;
    height: 74px;
    background-size: 200px 74px;
  }

  @media screen and (min-width: 1000px) {
    width: 297px;
    height: 110px;
    background-size: 297px 110px;
  }
}

.more {
  border-radius: 30px;
  background: #0575e6;
  padding: 8px 30px;
  color: #fff;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 50px;
}

.circles {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  background: url(../../../assets/auth/Group.svg) center no-repeat;

  @media screen and (min-width: 800px) {
    display: block;
    width: 300px;
    height: 213px;
    background-size: 300px 213px;
  }

  @media screen and (min-width: 1000px) {
    width: 431px;
    height: 306px;
    background-size: 431px 306px;
  }
}
